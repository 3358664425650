/* eslint-disable import/prefer-default-export */
import { GraphQLError } from 'graphql'
import { Buffer } from 'buffer'
// @ts-ignore
import { Status } from './__generated__/protobuf/ts/google/rpc/status'
// @ts-ignore
import {
  Severity,
  Error as ProtoError,
  // @ts-ignore
} from './__generated__/protobuf/ts/errors/errors'

// grabs the severity from gql errors. Apollo client returns an array of errors-can be more than one, I think from nested resolvers
// behavior should be returning the highest severity out of all of the errors.
// Ordering is
// SEV1
// SEV2 = SEV Unspecified
// SEV 3
// SEV Ignore
const SeverityOrder: { [key in Severity]: number } = {
  [Severity.SEVERITY_ONE]: 1,
  [Severity.SEVERITY_TWO]: 2,
  [Severity.SEVERITY_UNSPECIFIED]: 3,
  [Severity.SEVERITY_THREE]: 4,
  [Severity.SEVERITY_IGNORE]: 5,
}

// redefined the type here. Comes from @apollo/client/errors but we don't want to have that be a dependency
// in flock-gql-server.
type GraphQLErrors = ReadonlyArray<GraphQLError>

// WARNING: If this function is erroring, verify that flock-gql-server/src/__generated__/errors and /google
// have been generated, manually babel transpiled, and copied to the utils package. See instructions in
// protobuf/utils/errors/errors.proto
export const parseSeverityFromGqlErrors = (
  errors: GraphQLErrors | undefined
) => {
  if (!errors) {
    return null
  }
  const severities = errors.map((error) => {
    const firstErrorMetadata =
      error.extensions.exception.meta?.['grpc-status-details-bin']
    if (firstErrorMetadata) {
      try {
        const binaryData = Buffer.from(firstErrorMetadata, 'base64')
        const status = Status.fromBinary(binaryData)
        for (let i = 0; i < status.details.length; i += 1) {
          const detail = status.details[i]
          if (detail.typeUrl === 'type.googleapis.com/errors.Error') {
            const errorDetails = ProtoError.fromBinary(detail.value)
            if (errorDetails) {
              return errorDetails.severity
            }
          }
        }
      } catch (e) {
        console.error('Error parsing error details', e)
      }
    }
    return Severity.SEVERITY_UNSPECIFIED
  })
  if (severities.length === 0) {
    return Severity.SEVERITY_UNSPECIFIED
  }
  const highestSev = severities.reduce((acc, curr) =>
    SeverityOrder[curr] < SeverityOrder[acc] ? curr : acc
  )
  return highestSev
}
